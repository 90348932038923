import { useEffect, useState } from 'react';

import styled from '@emotion/styled';

import { getCustomCookie, setCustomCookie } from '@fllite-fe/api/src/utils/tokens';

import { getContextForApp } from '../utils/config';
import { Button } from './Button';
import { Text } from './Text';

const COOKIE_NAME = 'cookies_accepted';

const Container = styled('div')`
	width: 100vw;
	position: fixed;
	bottom: 0;
	left: 0;
	background: ${({ theme }) => theme.colors.primaryDarker};
	z-index: 999;

	${({ theme }) => theme.breakpoints.up('md')} {
		height: 82px;
	}
`;

const Content = styled('div')`
	margin: 0 auto;
	width: 100%;
	max-width: ${({ theme }) => theme.dimensions.pageWidth};
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;

	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		justify-content: space-between;
		padding: 10px 0;
	}
`;

const Link = styled('a')`
	color: ${({ theme }) => theme.colors.secondary};
	cursor: pointer;
	text-decoration: none;

	:hover {
		color: ${({ theme }) => theme.colors.secondary};
	}
`;

const TextContainer = styled('div')`
	margin-bottom: 20px;
	text-align: center;

	${({ theme }) => theme.breakpoints.up('md')} {
		margin-right: 20px;
		text-align: left;
		margin-bottom: 0;
	}
`;

const DetailsLink = ({ children, frontfacingUrl }) => (
	<Link href={`${frontfacingUrl}/privacy`} target="_blank" rel="noopener noreferrer">
		{children}
	</Link>
);

export const CookieBar = () => {
	const [isShown, setIsShown] = useState(false);
	const { cookieDomain, frontfacingUrl } = getContextForApp();

	useEffect(() => {
		const cookie = getCustomCookie(COOKIE_NAME);

		if (!cookie) {
			setIsShown(true);
		}
	}, []);

	const handleAllowCookiesClick = () => {
		setCustomCookie(COOKIE_NAME, 'true', { path: '/', maxAge: 365 * 24 * 60 * 60, cookieDomain });
		setIsShown(false);
	};

	if (!isShown) {
		return null;
	}

	return (
		<Container>
			<Content>
				<TextContainer>
					<Text>
						This website makes use of cookies to enhance browsing experience and provide additional
						functionality. <DetailsLink frontfacingUrl={frontfacingUrl}>Details</DetailsLink>
					</Text>
				</TextContainer>

				<Button secondary onClick={handleAllowCookiesClick}>
					Allow Cookies
				</Button>
			</Content>
		</Container>
	);
};
